(function($) {

  $('.tabgroup > div').hide();
  $('.tabgroup > div:first-of-type').show();
  $('.tabs a').click(function(e){
    e.preventDefault();
      var $this = $(this),
          tabgroup = '#'+$this.parents('.tabs').data('tabgroup'),
          others = $this.closest('li').siblings().children('a'),
          items  = $this.closest('ul').children('li'),
          target = $this.attr('href'),
          imgsrc = $this.attr('data-imgsrc'),
          index = target.slice(-1);
      others.removeClass('active');
      items.removeClass('active');
      $this.addClass('active');
      $this.parent().addClass('active');
      $(tabgroup).children('div').hide();
      $(target).show();
      // switch image when changing tab
      $('.featured-img > img').attr("src", imgsrc);

  });

})(jQuery);
